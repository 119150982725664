(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/shape-env/assets/javascripts/use-shape-env.js') >= 0) return;  svs.modules.push('/components/oddset/shape-env/assets/javascripts/use-shape-env.js');
"use strict";


const logger = new svs.core.log.Logger('components:oddset:shape-env');
const useShapeEnv = () => {
  const {
    shapeEnv
  } = svs.oddset.shapeEnv.data;
  if (!shapeEnv) {
    logger.error('No shape environment configured');
  }
  return {
    shapeEnv
  };
};
setGlobal('svs.components.oddset.shapeEnv', {
  useShapeEnv
});

 })(window);